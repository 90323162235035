import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins';
import 'vuetify/dist/vuetify.min.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// import VueVirtualScroller from 'vue-virtual-scroller'
import VueObserveVisibility from 'vue-observe-visibility';

import router from './router'
require("./assets/all.css");
// Vue.use(VueVirtualScroller)
Vue.use(VueObserveVisibility);
Vue.config.productionTip = false


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
