<template>
  <div class="container">
    
    <v-dialog width="800"  v-model="showBigImage">
      <img :src="`/api${bigImage}`" style="width:800px;" alt="">
    </v-dialog>
    <v-dialog color="white" width="800"  v-model="deleteConfirm">
      <div style="background:#fff; padding:15px;">

      
      Are you sure you want to delete this note?
      </div>
      <div style="display:flex; background:#fff; padding:15px; justify-content:flex-end">
      <v-btn color="gray" style="margin-right:10px;" @click="deleteConfirm = false">Cancel</v-btn>
      <v-btn color="red" dark @click="deleteNote(activeDeleteNote)">Delete</v-btn>
      </div>
    </v-dialog>
  <div class="add-note-container">
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      class="combo"
      hide-selected
      label="Search for a tag"
      multiple
      small-chips
      @change="search = ''"
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Create</span>
          <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span style="color:#fff" class="pr-2">
            {{ item.text }}
          </span>
          <v-icon style="color:#fff" small @click="parent.selectItem(item)">close</v-icon>
        </v-chip>
      </template>
      <template  v-slot:item="{ index, item }">
        
        <v-list-item dense >
          <v-text-field
            v-if="editing === item"
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-chip  v-else :color="`${item.color} lighten-3`" pill dark label small>
            {{ item.text }}
          </v-chip>
        </v-list-item>
        <v-spacer></v-spacer>
        <!-- <v-list-item-action @click.stop>
          <v-btn icon @click.stop.prevent="edit(index, item)">
            <v-icon>{{ editing !== item ? "edit" : "check" }}</v-icon>
          </v-btn>
        </v-list-item-action> -->
      </template>
    </v-combobox>
    <div class="uploadBtn">
        <input style="position:absolute; pointer-events: none; opacity: 0;" type="file" multiple ref="documents" @change="selectedFiles">
        <v-btn icon :color="'#308038'" dark depressed @click="selectDocuments"><v-icon>add_photo_alternate</v-icon></v-btn>
    </div>
    <div style="display:flex; flex-wrap: wrap; align-items: center; width:100%;">
                    <div v-for="(doc,i) in documents" :key="i" class="fileIcon" :alt="doc.name" :title="doc.name">
                        <i :class="getClass(doc.type)"></i><br> {{doc.name}}
                    </div>
                </div>
    <v-textarea style="width:100%;" outline placeholder="Write your note here..." label="Note" color="#308038" v-model="activeNote"> </v-textarea>

    <v-btn :loading="loading" color="#308038" class="white--text add-note" depressed small @click="addNote">Add Note</v-btn>
    </div>
    
    <v-tabs class="tab-container" v-model="active" show-arrows>
     <v-tab
        v-for="(tab,i) in ['Selector', ...availableNoteFilters]"
        :key="i"
        ripple
      >
        {{tab}}

      </v-tab>
      <v-tab-item :key="i" v-for="(tab,i) in availableNoteFilters">
          <div v-if="active == 0">
            <div class="note-filter">
              <div style="display:flex; width:100%;">

              
        <v-combobox class="combo" placeholder="Select Tags" label="Tags" small-chips multiple v-model="noteFilter" :items="availableNoteFilters">
          <template v-slot:prepend-item>
              <v-list-item
                ripple
                 style="text-align:left;"
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="selectedAll ? '#3080e0' : '#808080'" >{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:item="{ item }">
              <div  style="text-align:left;">
                {{item}}
              </div>
            </template>
        </v-combobox>
        <v-btn style="margin-left:auto;" large dark @click="$router.push(`/print?subjects=${noteFilter.join(',')}`)"  icon :color="'#3080e8'"  fab><i class="fa fa-print"></i></v-btn>
        </div>
        <v-text-field label="Search" placeholder="search..."  v-model="term"></v-text-field>
    </div>
          <div class="notes">
          <div v-for="(note,i) in displayedNotes" :key="i" class="note">
              <div class="note-time">{{moment(note.created_at).format('MM/DD/YYYY hh:mm')}} <v-btn v-if="!editMode" icon @click="activateEdit(note._id, note.note)"><v-icon small>edit</v-icon></v-btn> <v-btn v-if="editMode" icon @click="applyEditNote"><v-icon small>check</v-icon></v-btn><v-btn icon @click="activeDeleteNote = note._id, deleteConfirm = true"><v-icon small>close</v-icon></v-btn></div>
              <div v-for="(tag,p) in note.tags" :key="p" class="note-tag" :class="tag.color"> {{tag.text}}</div>
              
              <div v-if="editNoteId != note._id" class="note-body" >
                  <span v-html="note.note.replace('{{', `<span class='blurme'>`).replace('}}',`</span>`).replace(new RegExp(term, 'gi'),`<span class='highlight'>${term}</span>`)"></span>
              </div>

              <div v-else>
                <textarea :ref="note._id" class="editNode" v-model="editNote" />
              </div>
              <div class="note-images">
                <img @click="bigImage = pic, showBigImage = true" v-for="(pic,f) in note.pics" :key="f" :src="`/api${pic}`" alt="">
              </div>
              
          </div>
          <div v-observe-visibility="handleScrolledToBottom"></div>
        </div></div>
        <div v-else>
            <div class="special-note-item" v-for="(note,n) in getSpecialNotes(availableNoteFilters[i - 1])" :key="n">
              <span style="color:#ff0000; font-weight:bold;">{{n + 1}}.</span> <span v-html="note.note.replace('{{', `<span class='blurme'>`).replace('}}',`</span>`)"></span>
              <div class="note-images">
                <img @click="bigImage = pic, showBigImage = true" v-for="(pic,f) in note.pics" :key="f" :src="`/api${pic}`" alt="">
              </div>
            </div>
        </div>
      </v-tab-item>
    </v-tabs>
    
  </div>
</template>
<script >
import axios from "axios";
import moment from "moment";
// import heic2any from "heic2any";
export default {
  name: "home",
  data() {
    return {
      activeDeleteNote:null,
      deleteConfirm:false,
      active:0,
      showBigImage:false,
      bigImage:null,
      benched: 0,
      URL,
      editNote:'',
      editNoteId:'',
      editMode:false,
      moment,
      activeNote: "",
      loading: false,
      activator: null,
      attach: null,
      notes:[],
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange", "blue"],
      editing: null,
      index: -1,
      noteFilter:[],
      items: [
        { header: "Select a tag or create one" },
        
      ],

      nonce: 1,
      menu: false,
      model: [
        
      ],
      x: 0,
      search: null,
      y: 0,
      documents:[],
      page:1,
      perPage:10,
      last_page:20,
      term: ''
    };
  },
  mounted(){
      this.getItems();
      this.getNotes();
      
      
  },
  methods: {
    deleteNote(noteId){
      axios.delete(`/api/deleteNote/${noteId}`).then(()=>{
        this.deleteConfirm = false;
        this.notes.splice(this.notes.findIndex((item)=>item._id == noteId), 1)
      })
    },
    getClass(type){
            if(type == 'image/jpeg'){
                return 'fal fa-file-image f-image'
            }
            else if(type == 'application/vnd.ms-excel'){
                return 'fal fa-file-csv f-csv'
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                return 'fal fa-file-excel f-excel'
            }
            else if(type == 'application/pdf'){
                return 'fal fa-file-pdf f-pdf'
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                return 'fal fa-file-word f-word'
            }
            else{
                return 'fal fa-file'
            }
        },
    handleScrolledToBottom(isVisible){
      //console.log(isVisible);
      if(!isVisible) return
      if(this.filteredNotes.length > 0 && this.displayedNotes.length <= this.page*this.perPage ){
        this.page++
      }
      
    },
    getPage(){

    },
    getSpecialNotes(topic){
     
      if(topic == undefined)return [];
      let n = this.notes.filter((item)=>{
              let tags = item.tags.map((t)=>t.text);
              tags = tags.map((t)=>t.toLowerCase())
              let nFilter = [topic.toLowerCase()];
              for(let i in tags){
                  if(nFilter.includes(tags[i])){
                      return item;
                  }
              }
              //this.availableNoteFilters.indexOf(tags)
            })
  if(n == null){
    return [];
  }
      // n.sort((item, nextItem)=>{
      //   if(item.pics == null)return -1;
      //   if(nextItem.pics == null) return -1;
      //   if(nextItem.pics.length < item.pics.length){
      //     return 1;
      //   }
      //   if(nextItem.pics.length > item.pics.length){
      //     return -1
      //   }
      //   return 0
      // })
 
      
      return n
    },
    selectDocuments(){
        this.$refs.documents.click();
    },
    selectedFiles(e){
     
        this.documents = e.target.files;
        
        
    },
    toggle () {
        this.$nextTick(() => {
          if (this.selectedAll) {
            this.noteFilter = []
          } else {
            this.noteFilter = this.availableNoteFilters;
          }
        })
      },
      async getItems(){
          let items =  await axios.get('/api/items');
          items = items.data.items;
          items = items.map((i)=>{
              return {text:i.title,color: i.color}
          })
          this.items.push(...items);
      },
      activateEdit(id, note){
        this.editMode = true;
        this.editNote = note;
        this.editNoteId = id;
        setTimeout(()=>{
          console.log(this.$refs[id]);
          this.$refs[id][0].focus();
        },10)
        
      },
      async getNotes(){
          let notes =  await axios.get('/api/notes');
          notes = notes.data.items;
          notes.reverse();
          this.notes = notes;
          this.noteFilter = this.availableNoteFilters;
      },
      applyEditNote(){
        console.log('editing');
        let id = JSON.parse(JSON.stringify(this.editNoteId));
        let note = JSON.parse(JSON.stringify(this.editNote));
        this.notes[this.notes.findIndex((i)=>i._id == id)].note = note;
        this.editMode = false;
        this.editNote = '';
        this.editNoteId = '';
        axios.put('/api/edit_note', {id, update:{note}}).then(()=>{

        })
      },
    addNote() {
      this.loading = true;
      let formData = new FormData;
      formData.append('note', this.activeNote)
      //formData.append('tags[]', this.model)
      for (var f = 0; f < this.model.length; f++) {
          formData.append('tags[]', JSON.stringify(this.model[f]));
      }
      for(let i = 0; i < this.documents.length; i++){
          formData.append('pics', this.documents[i] )
      }

      axios.post("/api/add_note", formData).then(
        () => {
          this.notes.unshift({ note: this.activeNote, tags:this.model, created_at:new Date() })
          this.loading = false;
          this.activeNote = "";
          this.documents = [];
          this.getNotes();
          //this.model = []
          
        },
        (err) => {
          window.alert("There was an error.");
        }
      );
    },
    edit(index, item) {

      if (!this.editing) {
        console.log('edit', item);
        this.editing = item;
        this.index = index;
      } else {
        this.editing = null;
        this.index = -1;
      }
    },
    filter(item, queryText, itemText) {
      
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  computed: {
    selectedAll () {
        return this.availableNoteFilters.length === this.noteFilter.length
      },
    selectedSome () {
      return this.noteFilter.length > 0 && !this.selectedAll
    },
    icon () {
        if (this.selectedAll) return 'check_box'
        if (this.selectedSome) return 'indeterminate_check_box'
        return 'check_box_outline_blank'
      },
      displayedNotes(){
        let lengthToDisplay = this.perPage * this.page;
        return this.filteredNotes.slice(0, lengthToDisplay)
      },
      filteredNotes(){
        if(this.notes == []){
          return []
        } 
          return this.notes.filter((item)=>{
            //let term = new RegExp(this.term, "gi");
            if(item.note.toLowerCase().includes(this.term.toLowerCase(), 0)){
              let tags = item.tags.map((t)=>t.text);
              tags = tags.map((t)=>t.toLowerCase())
              let nFilter = this.noteFilter.map((f)=>f.toLowerCase());
              for(let i in tags){
                  if(nFilter.includes(tags[i])){
                      return item;
                  }
              }
            }
              //this.availableNoteFilters.indexOf(tags)
            })

      },
      availableNoteFilters(){
          let items = this.items;
          let arr = [];
          for(let i=1; i < items.length;i++){
              arr.push(items[i])
          }
          return arr.map((i)=>{
              if(i.text != null){
                return i.text.toUpperCase();  
              }
              
          })

      }
  },
  watch: {
    filteredNotes(v){
      if(v.length < this.page * this.perPage){
        this.page = 1;
      }
      // this.page = 1
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[Math.floor(Math.random() * 6) + 1  ],
          };
          axios.post('/api/add_item', {item:v.text, color:v.color});
          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },
};
</script>
<style lang="scss">
.blurme{
  transition:all .3s ease;
  cursor:pointer;
  padding:5px;

  &:hover{
    filter:blur(0);
  }
  filter:blur(3px);
}
.tab-container{
  position:relative;
  
}
.special-note-item{
  white-space: pre-wrap;
  text-align: left;
  margin-top:15px;
}
.scroller{
  height:100vh;
  width:100%;
  position: fixed !important;
  top:0;
  left:0;
  
}
.note-images{
  display:flex;
  img{
    border:solid 2px transparent;
    transition:all .3s ease;
    cursor: pointer;
    margin-top:10px;
    &:hover{
      border:solid 2px #3080e8;
    }
    width:200px;
    height:intrinsic;
    margin-right:10px;
    border-radius: 5px;
  }
}
.previewImage{
  width:250px;
  height:intrinsic;
  border-radius: 10px;
  overflow: auto;
  margin:10px;
  
}

.uploadBtn{
  align-items: flex-start;
  display: inline-flex;
}
.v-chip__content{
  text-transform: uppercase;
}
.v-menu__content{
  min-height:unset !important;
  max-height:50vh !important;
  
  
}
.editNode{
  white-space: pre-wrap;
  background:#fff;
  border-radius: 10px;
  padding:10px;
  font-size:10pt;
  width:100%;
  height:150px;
}
.combo{
    max-width:500px !important;
}
.note-time{
    color:#00000099;
    font-size:9pt;
    margin-bottom:10px;
}
.add-note{
    margin-left:auto;
    max-width:150px;
}
.add-note-container{
    display:flex;
    flex-direction: column;
    margin-bottom:50px;
}
.container {
  background: #fff;
  padding: 15px;
}
.note-tag{
    display:inline-block; 
    margin-bottom:10px;
    padding:2px 10px;
    color:#fff;
    font-size:9pt;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 4px;
}
.green{
    background:green;
}
.blue{
    background:blue
}
.purple{
    background:purple;
}
.note{
    text-align: left;
    margin-bottom:25px;
}
.note-body{
    background:#f8fafb;
    padding:15px;
    text-align:left;
    white-space: pre-wrap;
}
.indigo{
    background:indigo
}.cyan{
  background:cyan  
 } .teal{
     background:teal
 } 
 .orange{
     background:orange
 }
 .fileIcon{
    flex-basis: 33.3333%;
    padding: 15px;
    max-width:150px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fileIcon i{
    font-size:25pt;
    color:#000;
}
.f-image{
    color:#2281cf !important;
}
.f-excel{
    color:#25794b !important;
}
.f-word{
    color:#314ba1 !important;
}
 .f-pdf{
    color:#ac2a2a !important;
}
 .f-csv{
    color:#25794b !important;
}
.highlight{
  background:#ff0;
}
</style>