<template>
  <div class="print">
    <div class="special-note-item" v-for="(note, n) in filteredNotes" :key="n">
        <div style="display:flex; align-items:center; margin-top:5px;">
<span style="color: #ff0000; font-weight: bold; display:block;">{{ n + 1 }}.</span>
        
      <v-chip :style="`background:${note.tags[0].color}; margin-left:10px; color:#fffff`" dark label small>
            {{ note.tags[0].text }}
      </v-chip>
      &nbsp;&nbsp;
      
      </div>
      <span v-html="note.note" style="margin-left:15px; display:block;"></span>
      <div class="note-images">
        <img
          class="image-1"
          :key="f"
          v-for="(pic, f) in note.pics"
          :src="`https://docs.dexhonsa.me/api${pic}`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "print",
  data() {
    return {
      moment,
      isLoading: false,
      notes: [],
      displayedNotes: [],
      titleTag: "",
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange", "blue"],
      titleTags:[]
    };
  },
  async mounted() {
    await this.getNotes();
  },
  methods: {
      isTitleTag(tag){
          
          if(this.titleTags.includes(tag)){
              
              return true;
          }else{
              console.log('false');
              this.titleTags.push(tag);
              return true;
          }
      },
    sortArray(arr1, arr2) {
      function first(arr, low, high, x, n) {
        if (high >= low) {
          // (low + high)/2;
          let mid = low + Math.floor((high - low) / 2);

          if ((mid == 0 || x > arr[mid - 1]) && arr[mid] == x) return mid;
          if (x > arr[mid]) return first(arr, mid + 1, high, x, n);
          return first(arr, low, mid - 1, x, n);
        }
        return -1;
      }

      // Sort A1[0..m-1] according to the order
      // defined by A2[0..n-1].
      function sortAccording(A1, A2, m, n) {
        // The temp array is used to store a copy
        // of A1[] and visited[] is used to mark the
        // visited elements in temp[].
        let temp = [];
        let visited = [];

        for (let i = 0; i < m; i++) {
          temp[i] = A1[i].tags[0];
          visited[i] = 0;
        }

        // Sort elements in temp
        temp.sort(function (a, b) {
          return a - b;
        });

        // for index of output which is sorted A1[]
        let ind = 0;

        // Consider all elements of A2[], find them
        // in temp[] and copy to A1[] in order.
        for (let i = 0; i < n; i++) {
          // Find index of the first occurrence
          // of A2[i] in temp
          let f = first(temp, 0, m - 1, A2[i], m);
          // If not present, no need to proceed
          if (f == -1) {
            continue;
          }
          // Copy all occurrences of A2[i] to A1[]
          for (let j = f; j < m && temp[j] == A2[i]; j++) {
            A1[ind++].tags[0] = temp[j];
            visited[j] = 1;
          }
        }
        // Now copy all items of temp[] which are
        // not present in A2[]
        for (let i = 0; i < m; i++) {
          if (visited[i] == 0) A1[ind++].tags[0] = temp[i];
        }
      }
      let m = arr1.length;
      let n = arr2.length;
      return sortAccording(arr1,arr2, m, n)
    },
    getTitleTag(note) {
      let tags = note.tags.map((item) => item.text);
      //console.log(tags);
    },
    async getNotes() {
      let notes = await axios.get("/api/notes");
      notes = notes.data.items;
      console.log(this.sortArray(notes, this.filteredNotes));
      notes.reverse();
      console.log(notes.sort((item,nextItem)=>{
            if (item.tags[0].text > nextItem.tags[0].text) {
                return 1;
            }
            if (item.tags[0].text < nextItem.tags[0].text) {
                return -1;
            }
            return 0;
         
          }))
      this.notes = notes.sort((item,nextItem)=>item.tags[0].text-nextItem.tags[0].text);
    },
  },
  computed: {
    subjects() {
      return this.$route.query.subjects.split(",");
    },
    filteredNotes() {
      if (this.notes == []) {
        return [];
      }
      let n = this.notes.filter((item) => {
        //let term = new RegExp(this.term, "gi");

        let tags = item.tags.map((t) => t.text);
        tags = tags.map((t) => t.toLowerCase());
        let nFilter = this.subjects.map((f) => f.toLowerCase());
        for (let i in tags) {
          if (nFilter.includes(tags[i])) {
            return item;
          }
        }

        //this.availableNoteFilters.indexOf(tags)
      });
      n.sort((item, nextItem)=>{
        if(item.pics == null)return -1;
        if(nextItem.pics == null) return -1;
        if(nextItem.pics.length < item.pics.length){
          return 1;
        }
        if(nextItem.pics.length > item.pics.length){
          return -1
        }
        return 0
      })
      return n

    },
  },
};
</script>
<style lang="scss">
.title-tag {
  width: 100%;
  margin: 50px 0px;
  font-size: 25pt;
  font-weight: bold;
  text-decoration: underline;
}
img {
  page-break-inside: avoid !important;
}
// .image-1{
//     width:50% !important;
//     position: relative;
//     display: block;
//     // page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
//     // page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
//     page-break-inside: avoid !important; /* or 'auto' */
// }
.print {
  position: relative;
  width: 100%;
  margin: 0;
 
}

.blurme {
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 5px;

  &:hover {
    filter: blur(0);
  }
  filter: blur(3px);
}
.tab-container {
  position: relative;
}
.special-note-item {
  white-space: pre-wrap;
  position: relative;
  page-break-inside: avoid !important;
  text-align: left;
  margin-top: 5px;
}
.scroller {
  height: 100vh;
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
}
.note-images {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  page-break-inside: avoid !important;
  img {
    page-break-inside: avoid !important;

    border: solid 2px transparent;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 10px;
    &:hover {
      border: solid 2px #3080e8;
    }
    width: 200px;
    flex-basis: 50%;
    max-width: 50%;
    min-width: 30vw;
    flex: 1;
    height: intrinsic;
    margin-right: 10px;
    border-radius: 5px;
  }
}
.previewImage {
  width: 250px;
  height: intrinsic;
  border-radius: 10px;
  overflow: auto;
  margin: 10px;
}

.uploadBtn {
  align-items: flex-start;
  display: inline-flex;
}
.v-chip__content {
  text-transform: uppercase;
}
.v-menu__content {
  min-height: unset !important;
  max-height: 50vh !important;
}
.editNode {
  white-space: pre-wrap;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  font-size: 10pt;
  width: 100%;
  height: 150px;
}
.combo {
  max-width: 500px !important;
}
.note-time {
  color: #00000099;
  font-size: 9pt;
  margin-bottom: 10px;
}
.add-note {
  margin-left: auto;
  max-width: 150px;
}
.add-note-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.container {
  background: #fff;
  padding: 15px;
}
.note-tag {
  display: inline-block;
  margin-bottom: 10px;
  padding: 2px 10px;
  color: #fff;
  font-size: 9pt;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 4px;
}
.green {
  background: green;
}
.blue {
  background: blue;
}
.purple {
  background: purple;
}
.note {
  text-align: left;
  margin-bottom: 25px;
}
.note-body {
  background: #f8fafb;
  padding: 15px;
  text-align: left;
  white-space: pre-wrap;
}
.indigo {
  background: indigo;
}
.cyan {
  background: cyan;
}
.teal {
  background: teal;
}
.orange {
  background: orange;
}
.fileIcon {
  flex-basis: 33.3333%;
  padding: 15px;
  max-width: 150px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileIcon i {
  font-size: 25pt;
  color: #000;
}
.f-image {
  color: #2281cf !important;
}
.f-excel {
  color: #25794b !important;
}
.f-word {
  color: #314ba1 !important;
}
.f-pdf {
  color: #ac2a2a !important;
}
.f-csv {
  color: #25794b !important;
}
.highlight {
  background: #ff0;
}
</style>